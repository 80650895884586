import { Brand } from '../app/brand/brand';

export const environment = {
  envName: 'demo-sandbox',
  production: false,
  debugMode: false,
  demo: true,
  apiUrl: 'http://localhost:5001/Onboarding.API/',
  appTitle: '[DEMO] Liberty - Online Account Activation',
  resendShowAfterSeconds: 5,
  heapAnalytics: {
    include: false,
    heapId: null as number | null
  },
  hotjar: {
    include: false,
    hotjarId: null as number | null
  },
  sentry: {
    dsn: null as string
  },
  applicationInsights: {
    connectionString: 'InstrumentationKey=ac4eda0e-33cd-42db-a89a-7d3a584f332b;IngestionEndpoint=https://australiasoutheast-0.in.applicationinsights.azure.com/;LiveEndpoint=https://australiasoutheast.livediagnostics.monitor.azure.com/;ApplicationId=0311c02e-a617-4c43-8d2d-5e6ac42f7cea',
  },
  overrideBrand: Brand.LibertyAu
};
